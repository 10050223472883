import { config_utils } from '@/services/configs/config_utils'
import request from '@/services/axios_instance'
import _ from 'lodash'
export default {
    name: 'GeneralSettings',
    mixins: [config_utils],
    data: () => ({
        paths: [
            { text: 'Admin', disabled: false, router_name: 'admin-dashboard' },
            { text: 'General', disabled: false, router_name: '' }
        ],
        signup: false,
        login: true,
        subscription: false,
        maintenance_mode: false,
        modules: [],
        dashitems: [],
        dashtiles: [],
        panel_items: 10,
        signuploginbtn: false,
        allowed_modules_btn: false,
        allowed_dashcards_btn: false,
        allowed_dashtiles_btn: false,
        recaptcha_btn: false,
        meta_btn: false
    }),
    created() {
        request.get(`api/configs`).then(({ data }) => {
            this.dashitems = data.dashcards_mapping
            this.dashtiles = data.dashtiles_mapping
            this.modules = data.modules_mapping
            this.signup = data.signup
            this.login = data.login
            this.subscription = data.subscription
            this.maintenance_mode = data.maintenance_mode
        })
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.all()
    },
    methods: {
        save(key) {
            this[`save_${key}`]()
        },
        save_login_signup() {
            this.signuploginbtn = true
            this.sendUpdateConfigs(
                `api/configs/allowed_modules`, {
                    modules: {
                        login: this.login,
                        signup: this.signup,
                        subscription: this.subscription,
                        maintenance_mode: this.maintenance_mode
                    }
                },
                () => {
                    this.signuploginbtn = false
                }
            )
        },
        save_allowed_modules() {
            this.allowed_modules_btn = true
            this.sendUpdateConfigs(
                `api/configs/allowed_modules`, { modules: this.modules },
                () => {
                    this.allowed_modules_btn = false
                }
            )
        },
        save_allowed_dashcards() {
            this.allowed_dashcards_btn = true
            this.sendUpdateConfigs(
                `api/configs/update-array`, { items: this.dashitems, key: 'allowed_dashcards' },
                () => {
                    this.allowed_dashcards_btn = false
                }
            )
        },
        save_allowed_dashtiles() {
            this.allowed_dashtiles_btn = true
            this.sendUpdateConfigs(
                `api/configs/update-array`, { items: this.dashtiles, key: 'allowed_dashtiles' },
                () => {
                    this.allowed_dashtiles_btn = false
                }
            )
        }
    }
}